import http from "@/utils/http";
import { ResourceParams, ResourceVParams, IScoreParams } from "@/typings/index";

// 咨询-热榜列表
export const getHotList = (data: ResourceParams) =>
  http({
    url: "/api-bigScreen/netArmy/hotList/",
    method: "post",
    data,
  });

// 咨询-头条列表
export const getTtList = (data: ResourceParams) =>
  http({
    url: "/api-bigScreen/netArmy/ttList/",
    method: "post",
    data,
  });

// 咨询-动态列表
export const getVedioList = (data: ResourceVParams) =>
  http({
    url: "/api-bigScreen/netArmy/videoList/",
    method: "post",
    data,
  });

// 咨询-学习列表
export const getStudyList = (data: ResourceParams) =>
  http({
    url: "/api-bigScreen/netArmy/studyList/",
    method: "post",
    data,
  });

//用户积分日志表新增
export const addScore = (data: IScoreParams) =>
  http({
    url: '/api-user/tintegrallog/saveByType',
    method: 'post',
    data,
  });