
import { onMounted, reactive, ref } from "vue";
import {
  getHotList,
  getTtList,
  getVedioList,
  getStudyList,
  addScore,
} from "@/api/resource";
import { authLogin } from '@/utils/auth';

export default {
  beforeRouteEnter(to: any, _from: any, next: any) {
    authLogin(to).then(next)
  },

  setup() {
    const activeName = ref("opionion");

    const tabList = reactive([
      { label: "热榜", value: "opionion" },
      { label: "头条", value: "toutiao" },
      { label: "视频", value: "dynamic" },
      { label: "学习", value: "learn" },
    ]);

    const listState = reactive({
      downLoading: false,
      finished: false,
      pullLoading: false, //下拉加载
      dataList: [],
    });

    const params = {
      page: 1,
      limit: 10,
    };

    let locationMsg = {
      provinceName: "",
      cityName: "",
    };

    /*
      高德地图API 通过 IP查询当前省市
      账号key luchen 180****3612
      个人开发每日调用 300000 次
    */
    // const getLocationByIp = async () => {
    //   const resp = await fetch(
    //     "https://restapi.amap.com/v3/ip?key=da978722cb94cef8ad7c718ebadbd13b"
    //   );
    //   const data = await resp.json();
    //   if ((data as any).status === "1") {
    //     locationMsg.provinceName = (data as any).province || "";
    //     locationMsg.cityName = (data as any).city || "";
    //     // 查询地理位置成功后调用列表接口, 并保存 location 数据
    //     console.log('location-data:', JSON.stringify(data));
    //     sessionStorage.setItem('locationMsg', JSON.stringify(locationMsg));
    //     onLoad();
    //   } else {
    //     console.log("获取地理位置失败！");
    //   }
    // };

    onMounted(() => {
      // if (sessionStorage.getItem('locationMsg')) {
      //   locationMsg = JSON.parse(sessionStorage.getItem('locationMsg') as string);
      // } else {
      //   getLocationByIp();
      // }
    });

    const loadOpionion = async () => {
      const res1 = await getHotList({ ...params, ...locationMsg });
      listState.downLoading = false;
      listState.pullLoading = false;
      if ((res1 as any).resp_code === 0) {
        const { current, pages, records } = (res1 as any).datas;
        listState.dataList = listState.dataList.concat(records) || [];
        listState.finished = current >= pages;
        listState.finished || params.page++;
      } else {
        listState.finished = true;
      }
    }

    const loadToutiao = async () => {
      const res2 = await getTtList({ ...params, ...locationMsg });
      listState.downLoading = false;
      listState.pullLoading = false;
      if ((res2 as any).resp_code === 0) {
        const { current, pages, records } = (res2 as any).datas;
        listState.dataList = listState.dataList.concat(records) || [];
        listState.finished = current >= pages;
        listState.finished || params.page++;
      } else {
        listState.finished = true;
      }
    }

    const loadDynamic = async () => {
      const res3 = await getVedioList({
        ...params,
        cityName: locationMsg.cityName,
      });
      listState.downLoading = false;
      listState.pullLoading = false;
      if ((res3 as any).resp_code === 0) {
        const { current, pages, records } = (res3 as any).datas;
        listState.dataList = records || [];
        listState.finished = current >= pages;
        listState.finished || params.page++;
      } else {
        listState.finished = true;
      }
    }

    const loadLearn = async () => {
      const res4 = await getStudyList({ ...params, ...locationMsg });
      listState.downLoading = false;
      listState.pullLoading = false;
      if ((res4 as any).resp_code === 0) {
        const { current, pages, records } = (res4 as any).datas;
        listState.dataList = listState.dataList.concat(records) || [];
        listState.finished = current >= pages;
        listState.finished || params.page++;
      } else {
        listState.finished = true;
      }
    }

    const onLoad = async () => {
      switch (activeName.value) {
        case "opionion":
          loadOpionion();
          break;
        case "toutiao":
          loadToutiao();
          break;
        case "dynamic":
          loadDynamic();
          break;
        case "learn":
          loadLearn();
          break;
      }
    };

    const onClick = () => {
      listState.dataList = [];
      params.page = 1;
      if (!listState.finished) {
        onLoad();
      } else {
        listState.finished = false;
      }
    };

    const onRefresh = () => {
      setTimeout(() => {
        listState.dataList = [];
        params.page = 1;
        if (listState.pullLoading) {
          listState.pullLoading = false;
        }
        onLoad();
      }, 1000);
    };

    const filterImgUrl = (val: string[]) => {
      if (val) {
        return val.length > 3 ? val.slice(0, 3) : val;
      } else {
        return "";
      }
    };

    const addScores = (val: any) => {
      if (activeName.value === "dynamic") {
        const data = {
          articleContentId: val.contentId,
          articleUrl: val.url,
          type: 2,
        };
        addScore(data);
      }
      if (!val.content && !val.photoUrls && val.videoUrls) {
        const data = {
          articleContentId: val.contentId,
          articleUrl: val.url,
          type: 2,
        };
        addScore(data);
      } else {
        const data = {
          articleContentId: val.contentId,
          articleUrl: val.url,
          type: 1,
        };
        addScore(data);
      }
    };

    const toActicle = (v: any) => {
      addScores(v);
      window.location.href = v.url;
    };

    return {
      activeName,
      tabList,
      listState,
      onLoad,
      onClick,
      onRefresh,
      filterImgUrl,
      toActicle,
    };
  },
};
